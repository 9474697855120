<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row class="mb-2">
          <b-col md="10">
            <b-input-group>
              <b-form-input
                v-model="search"
                placeholder="Makine Kartı Ara"
              />
              <b-input-group-append>
                <b-button
                  v-if="search !== ''"
                  variant="warning"
                  @click="resetSearch"
                ><FeatherIcon icon="XIcon" /></b-button>
                <b-button
                  variant="primary"
                  :disabled="search === ''"
                  @click="searchData"
                ><FeatherIcon icon="SearchIcon" /></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col md="2">
            <b-button
              to="machines/add"
              variant="primary"
              block
            ><FeatherIcon icon="PlusIcon" />
              Ekle</b-button>
          </b-col>
        </b-row>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >
            <template #cell(customer)="data">
              <div class="font-weight-bold">
                {{ data.item.customer }}
              </div>
              <div class="text-muted">
                {{ data.item.customer_user }}
              </div>
            </template>
            <template #cell(machine_status)="data">
              <div class="text-center">
                <b-badge
                  variant="success"
                  pill
                >
                  {{ data.item.machine_status }}
                </b-badge>
              </div>
            </template>
            <template #cell(syear)="data">
              <div class="text-center">
                {{ data.item.syear }}
              </div>
            </template>
            <template #cell(control)="data">
              <b-dropdown
                :split-to="'/machines/view/' + data.item.id"
                split
                block
                size="sm"
                text="Görüntüle"
                variant="outline-primary"
              >
                <b-dropdown-item :to="'/machines/edit/' + data.item.id">
                  Güncelle
                </b-dropdown-item>
                <b-dropdown-item
                  target="_blank"
                  :href="$store.state.app.baseURL + '/exports/qrcode/machines?id=' + data.item.id + '&downloadToken=' + downloadToken"
                >
                  QRCODE
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table></b-card-text>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BTable,
  BCardFooter,
  BPagination,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BBadge,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

export default {
  name: 'Machines',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'project',
          label: 'PROJE NO',
          thStyle: { width: '160px', textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'customer',
          label: 'FİRMA ADI',
        },
        {
          key: 'machine_type',
          label: 'MAKİNE TİPİ',
          thStyle: { width: '200px', textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'machine_model',
          label: 'MAKİNE MODELİ',
          thStyle: { width: '200px', textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'syear',
          label: 'KURULUM YILI',
          thStyle: { width: '160px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'machine_status',
          label: 'DURUM',
          thStyle: { width: '120px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '160px', textAlign: 'center', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'machines.id AS id',
          'machines.project AS project',
          'customers.company AS customer',
          'customer_users.name AS customer_user',
          'machine_types.title AS machine_type',
          'machine_models.title AS machine_model',
          'machine_statuses.title AS machine_status',
          'machines.syear AS syear',
          'machines.status AS status',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      const data = this.$store.getters['machines/getMachines']
      return data
    },
    dataCount() {
      return this.$store.getters['machines/getMachinesCount']
    },
    downloadToken() {
      return localStorage.getItem('downloadToken')
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      this.dataQuery.or_like = {
        'machines.project': this.search,
        'machines.serial': this.search,
        'customers.company': this.search,
      }
      this.getDataList()
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.search = ''
      this.getDataList()
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('machines/machinesList', this.dataQuery)
    },
  },
}
</script>

<style scoped>

</style>
